import homepageBanner from './homepage.override';
import mobilePlanPageBanner from './mobilePlanPage.override';
import mobilePlanSummary from './mobilePlanSummary.override';
import { OfferContentManager } from '../OfferContentManager';

export type ISectionOverride = (correlationId: string, promoContentManager: OfferContentManager, section: any) => any;

interface ISectionOverridesDefinition {
  path: string[];
  contentType: string[];
  override: ISectionOverride;
}

export const overrides: ISectionOverridesDefinition[] = [homepageBanner, mobilePlanPageBanner, mobilePlanSummary];
