import { ISectionPrimaryBanner } from '@belong/types';
import { OfferContentManager } from '../OfferContentManager';

// HOME-PAGE: Override to replace the default banner with an ACTIVE campaign banner
export const override = (
  correlationId: string,
  contentManager: OfferContentManager,
  section: ISectionPrimaryBanner
): ISectionPrimaryBanner => {
  return contentManager.getCampaignOverride(correlationId, 'homepagePrimaryBanner') ?? section;
};

export default {
  // always include base-path in paths
  path: ['/go'],
  contentType: ['sectionPrimaryBanner'],
  override
};
