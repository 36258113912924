import {
  ICatalogOffer,
  ICatalogOfferReward,
  IOfferCampaign,
  OFFER_CHANNEL_ACTION,
  OFFER_REWARD_TYPE,
  PRODUCT_FAMILY
} from '@belong/types';
import {
  IAppliedOfferReward,
  IOffer,
  IOfferReward,
  OFFER_REWARD_ATTRIBUTE_NAME,
  OFFER_REWARD_FULFILMENT_TYPE,
  OFFER_STATUS
} from '@belong/types/api/offers';
import { IOfferDetails } from '@belong/types/api/orders';
import { isString } from '@belong/type-utils';
import { normaliseRewardProperties } from './templates';

export type OfferType = IOffer | ICatalogOffer | IOfferDetails;
export type RewardType = IOfferReward | ICatalogOfferReward | IAppliedOfferReward;
export type AnyOfferOrReward = OfferType | RewardType;

export const isActiveOrNewOffer = (offer: IOfferDetails): boolean =>
  [OFFER_STATUS.ACTIVE, OFFER_STATUS.NEW].includes(offer.status);

export const isActivationOffer = (offer: ICatalogOffer, productFamily: PRODUCT_FAMILY): boolean =>
  offer.channelAction === OFFER_CHANNEL_ACTION.ACTIVATION && offer.productFamily === productFamily;

export const isChangePlanOffer = (offer: ICatalogOffer, productFamily: PRODUCT_FAMILY): boolean =>
  offer.channelAction === OFFER_CHANNEL_ACTION.POST_ACTIVATION && offer.productFamily === productFamily;

export const isNewOffer = (offer: IOfferDetails): boolean => offer.status === OFFER_STATUS.NEW;

export const isOffer = (item?: AnyOfferOrReward): item is IOffer | ICatalogOffer => {
  if (!item) {
    return false;
  }
  return 'rewards' in item;
};

export const isAppliedOfferReward = (item?: AnyOfferOrReward): item is IAppliedOfferReward => {
  if (!item) {
    return false;
  }
  return 'offerId' in item && !('productAttributes' in item);
};

export const isOfferReward = (item?: AnyOfferOrReward): item is IOfferReward | ICatalogOfferReward => {
  if (!item) {
    return false;
  }
  return 'productAttributes' in item;
};

export const getRewardType = (item?: RewardType): OFFER_REWARD_TYPE => {
  if (!item) {
    return OFFER_REWARD_TYPE.UNKNOWN;
  }

  // Data Reward
  if (isDataReward(item)) {
    return OFFER_REWARD_TYPE.DATA;
  }
  // Credit Reward
  if (isCreditReward(item)) {
    return OFFER_REWARD_TYPE.CREDIT;
  }

  // Mystery item
  return OFFER_REWARD_TYPE.UNKNOWN;
};

export const getRewardFulfilmentType = (item?: RewardType): OFFER_REWARD_FULFILMENT_TYPE => {
  if (!item) {
    return OFFER_REWARD_FULFILMENT_TYPE.UNKNOWN;
  }

  return normaliseRewardProperties(item)[OFFER_REWARD_ATTRIBUTE_NAME.FULFILMENT_TYPE] as OFFER_REWARD_FULFILMENT_TYPE;
};

export const isDataReward = (item?: RewardType): boolean => {
  if (!item) {
    return false;
  }
  return OFFER_REWARD_ATTRIBUTE_NAME.DATA_SIZE in normaliseRewardProperties(item);
};

export const isCreditReward = (item?: RewardType): boolean => {
  if (!item) {
    return false;
  }
  return OFFER_REWARD_ATTRIBUTE_NAME.CREDIT_AMOUNT in normaliseRewardProperties(item);
};

// TRUE if reward has affected the service in the CURRENT bill cycle
export const isActiveReward = (reward: IAppliedOfferReward): boolean => {
  return (reward?.remainingMonths ?? 0) < (reward?.durationInMonths ?? 0);
};

// TRUE if reward will affect the service in the NEXT bill cycle
// (Assumes reward is either active or will start in next bill cycle)
export const isOnGoingReward = (reward: RewardType): boolean => {
  // Applied Offer Reward
  if (isAppliedOfferReward(reward)) {
    return reward.remainingMonths > 0;
  }

  if (isOfferReward(reward)) {
    const rewardProps = normaliseRewardProperties(reward);
    const isBillCycleAligned =
      rewardProps[OFFER_REWARD_ATTRIBUTE_NAME.FULFILMENT_TYPE] === OFFER_REWARD_FULFILMENT_TYPE.BILL_CYCLE_ALIGNED;
    return isRecurringReward(reward) || isBillCycleAligned;
  }

  return false;
};

// TRUE if reward duration is greater than 0
export const isRecurringReward = (reward?: IAppliedOfferReward | IOfferReward | ICatalogOfferReward): boolean => {
  if (!reward) {
    return false;
  }

  let duration = 0;
  if (isAppliedOfferReward(reward)) {
    duration = reward.durationInMonths ?? 0;
  } else {
    duration = parseInt(normaliseRewardProperties(reward)[OFFER_REWARD_ATTRIBUTE_NAME.DURATION]?.toString() ?? 0, 10);
  }

  return duration > 1;
};

export const getIdFromReward = (reward: IAppliedOfferReward): string => {
  if (isAppliedOfferReward(reward)) {
    return reward.offerId;
  }
  return '';
};

export const getDurationFromReward = (reward: IAppliedOfferReward | ICatalogOfferReward | IOfferReward): number => {
  if (isAppliedOfferReward(reward)) {
    return reward.durationInMonths;
  }

  return 0;
};

export const getIdFromOfferOrReward = (offerOrReward: string | ICatalogOffer | IAppliedOfferReward): string => {
  if (isString(offerOrReward)) {
    return offerOrReward;
  }
  if (isOffer(offerOrReward)) {
    return offerOrReward.id;
  }
  return offerOrReward.offerId;
};

export const campaignIncludesProductFamily = (campaign: IOfferCampaign, productFamily: string): boolean => {
  return campaign.offers.some(offer => offer.productFamily === productFamily);
};

/**
 * Check if the reward is a credit reward AND will affect the service in the NEXT bill cycle
 * This includes:
 *  - Applied rewards
 *  - Applied rewards starting in the next bill cycle
 *  - Unapplied catalogue rewards that will be added to the service
 */
export const isOnGoingCreditReward = (reward: IAppliedOfferReward | ICatalogOfferReward): boolean =>
  isCreditReward(reward) && isOnGoingReward(reward);
