import { ISectionPrimaryBanner, PRODUCT_FAMILY } from '@belong/types';
import { OfferContentManager } from '../index';

// MOBILE-PLAN-PAGE: Override to replace the default banner with an ACTIVE campaign banner
export const override = (
  correlationId: string,
  contentManager: OfferContentManager,
  section: ISectionPrimaryBanner,
  productFamily?: PRODUCT_FAMILY
): ISectionPrimaryBanner => {
  return contentManager.getCampaignOverride(correlationId, 'mobilePlanPageBanner', productFamily) ?? section;
};

export default {
  // always include base-path in paths
  path: ['/go/mobile'],
  contentType: ['sectionPrimaryBanner'],
  override
};
