import { NBN_PRODUCT_STATUS } from './nbn';
import { IAmount, ICharge, IChargeWithDisplayText, IRecurringCharge, IRecurringChargeWithDisplayText } from './price';
import { IAppliedOfferReward } from './api/offers';

export interface IData {
  unit: string;
  value: number;
}

export interface INetworkSpeed {
  upstream: IData;
  downstream: IData;
}

// generic product code interface
// for nbn and mobile product
export interface IProductCode {
  productCode: string;
}

export interface IProduct extends IProductCode {
  productName: string;
  productId: string;
  description: string;
}

export interface IMobilePlan extends IProduct {
  data: IData;
  price: IRecurringCharge;
  addons: IAddon[];
  availability: PlanAvailability;
  productFamily: typeof PRODUCT_FAMILY.MOBILE_PLANS;
}

export interface IMobileDataPlan extends IProduct {
  data: IData;
  price: IRecurringCharge;
  productFamily: typeof PRODUCT_FAMILY.MOBILE_DATA_PLANS;
  availability: PlanAvailability;
}

export enum PlanAvailability {
  ALL = 'ALL',
  PUBLIC = 'PUBLIC',
  GRANDFATHERED = 'GRANDFATHERED',
  CONDITIONAL = 'CONDITIONAL',
  SIM_RESTRICTED = 'SIM_RESTRICTED'
}

export interface IAnyPlan extends IProduct {
  data?: IData;
  networkSpeed?: INetworkSpeed;
  price: IRecurringCharge;
  addons: IAddon[];
  permitted?: boolean; // permitted is used to make plan card unavailable to choose
  promoCode?: string;
  campaignCode?: string;
  productFamily?: PRODUCT_FAMILY;
  availability?: PlanAvailability;
  status?: NBN_PRODUCT_STATUS;

  // Legacy nbn plans
  expiryDate?: string;
  futurePrice?: number;
}

export interface ITopUp extends IProduct {
  data: IData;
  price: ICharge;
}

export type EntitlementType = 'data' | 'voice';
export interface IIREntitlement extends IAmount {
  type: EntitlementType;
}
export interface IInternationalRoamingProduct extends IProduct {
  price: ICharge;
  productFamily: PRODUCT_FAMILY;
  entitlements: IIREntitlement[];
}

export interface IDataGift extends IProduct {}

export interface IAddon extends IProduct {
  included: boolean;
  description: string;
  price: IRecurringCharge;
}

export interface IIternationalRoamingEntitlement {
  type: string;
  balance: number;
  allowance: number;
  unit?: string;
}

export interface IInternationalRoamingService {
  id: string;
  name: string;
  productCode: string;
  status: InternationalRoamingServiceStatus;
  entitlements?: IIternationalRoamingEntitlement[];
  startDate?: string;
  endDate?: string;
  createdDate?: string;
}

export interface IPlan extends IProductCode {
  id: string;
  name: string;
  description: string;
  productId?: string;
  includedData: IData;
  price: IRecurringCharge;
  addons: IAddon[];
  rewards: IAppliedOfferReward[];
  isIpndListed?: boolean;
  productFamily?: typeof PRODUCT_FAMILY.MOBILE_PLANS | typeof PRODUCT_FAMILY.MOBILE_DATA_PLANS;
  internationalRoamingServices?: IInternationalRoamingService[];
}

export interface INbnPlanSpeed {
  downstreamSpeed: string;
  upstreamSpeed: string;
  typicalEveningDownstreamSpeed: string;
  typicalEveningUpstreamSpeed: string;
  dataSize: string;
  speedUnit: string;
}

export interface INbnModemOption extends IProduct {
  paymentOptions: (IChargeWithDisplayText | IRecurringChargeWithDisplayText)[];
}

export interface INbnNdcOption extends IProduct {
  paymentOptions: (IChargeWithDisplayText | IRecurringChargeWithDisplayText)[];
}

export interface INbnCatalogPlan extends IProduct {
  price: IRecurringChargeWithDisplayText;
  speed: INbnPlanSpeed;
  modemOptions: INbnModemOption[];
  ndcOptions: INbnNdcOption[];
  planProductCode?: string;
  campaignCode: string;
  active?: boolean;
}

export enum NBN_TECH_TYPE {
  FTTP = 'FTTP',
  FW = 'FW',
  FTTB = 'FTTB',
  HFC = 'HFC',
  FTTC = 'FTTC',
  FTTN = 'FTTN',
  SATELLITE = 'SATELLITE'
}

export const NBN_TECH_TYPE_LABELS: Record<NBN_TECH_TYPE, string> = {
  FTTB: 'Fibre to the Building',
  FTTC: 'Fibre to the Curb',
  FTTN: 'Fibre to the Node',
  FTTP: 'Fibre to the Premises',
  FW: 'Fixed Wireless',
  HFC: 'Hybrid Fibre Coaxial',
  SATELLITE: 'Satellite'
};

export enum PRODUCT_FAMILY {
  MOBILE_PLANS = 'Mobile Plans',
  FIXED_PLANS = 'Fixed Plans',
  MOBILE_DATA_PLANS = 'Mobile Data Plans',
  UNKNOWN = 'Unknown',
  INTERNET = 'Internet'
}

export enum InternationalRoamingServiceStatus {
  PENDING = 'PENDING',
  READY = 'READY',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export const InternationalRoamingEntitlementType = {
  data: 'data',
  voice: 'voice'
};
