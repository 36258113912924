import {
  IAppliedOfferReward,
  IOfferReward,
  OFFER_REWARD_ATTRIBUTE_NAME,
  OFFER_REWARD_FULFILMENT_TYPE,
  OFFER_REWARD_RECURRENCE
} from '@belong/types/api/offers';
import {
  ICatalogOffer,
  ICatalogOfferReward,
  IDictionary,
  OFFER_REWARD_TYPE,
  OFFER_REWARD_UNIT,
  PRODUCT_FAMILY,
  ProductAttributeMap
} from '@belong/types';
import { logger } from '@belong/logging';
import { applyDictionary, pluralise } from '@belong/type-utils';
import { formatDate } from '@belong/date-utils';
import { getRewardFulfilmentType, getRewardType, isAppliedOfferReward, isOffer, isRecurringReward } from '../utils';
import { OFFER_DATE_FORMAT, OFFER_TEMPLATE_TYPE, REWARD_TEMPLATE_IDENTIFIERS } from './config';
import { collectProductAttributes } from '../factories';
import { offerContentManager } from '../OfferContentManager';

// Only use when displaying pre-activation Offers
export function offerToDictionary(offer?: ICatalogOffer): IDictionary {
  if (!offer) {
    return {};
  }

  if (isOffer(offer)) {
    // ‼️NOTE: Currently only supports one reward per offer
    return {
      'offer:id': offer.id,
      'offer:name': offer.name,
      ...toOfferDateDictionary('offer:expiry', offer.endDate),
      ...rewardToDictionary(offer.rewards[0])
    };
  }

  logger.error('OfferToDictionary: Unsupported offer type', offer);
  return {};
}

function getFormattedRewardAmount(reward?: IAppliedOfferReward | ICatalogOfferReward | IOfferReward): string {
  if (!reward) {
    return '';
  }

  const type = getRewardType(reward);
  const props = normaliseRewardProperties(reward);
  let value: number = 0;

  if (type === OFFER_REWARD_TYPE.DATA) {
    value = props[OFFER_REWARD_ATTRIBUTE_NAME.DATA_SIZE] as number;
  }
  if (type === OFFER_REWARD_TYPE.CREDIT) {
    value = props[OFFER_REWARD_ATTRIBUTE_NAME.CREDIT_AMOUNT] as number;
  }

  return formatAmount(type, value);
}

export function rewardToDictionary(reward?: IAppliedOfferReward | ICatalogOfferReward | IOfferReward): IDictionary {
  if (!reward) {
    return {};
  }

  // Offer Rewards
  const properties = normaliseRewardProperties(reward);
  const dictionary: IDictionary = {
    'reward:amount': getFormattedRewardAmount(reward),
    'reward:duration': formatDuration(
      OFFER_REWARD_UNIT.duration,
      properties[OFFER_REWARD_ATTRIBUTE_NAME.DURATION] as number
    )
  };

  // Only add start dates for applied rewards
  if (isAppliedOfferReward(reward)) {
    Object.assign(dictionary, {
      ...toOfferDateDictionary('reward:start', reward.startDate),
      ...toOfferDateDictionary('reward:expiry', reward.expiryDate),
      'offer:id': reward.offerId,
      'offer:name': offerContentManager.getOfferNameById(reward.offerId)
    });
  }

  return dictionary;
}

// Gather props from available / applied offers and return in consistent format
export const normaliseRewardProperties = (
  reward?: IOfferReward | ICatalogOfferReward | IAppliedOfferReward
): ProductAttributeMap => {
  if (!reward) {
    return {};
  }

  // IAppliedOfferReward
  if (isAppliedOfferReward(reward)) {
    const { offerId, offerType, ...rewardProps } = reward;
    return rewardProps;
  }

  // IOfferReward (API Response)
  if (Array.isArray(reward.productAttributes)) {
    return collectProductAttributes(reward.productAttributes);
  }

  // ICatalogOfferReward
  return reward.productAttributes;
};

function formatAmount(type: OFFER_REWARD_TYPE, value: number): string {
  switch (type) {
    case OFFER_REWARD_TYPE.DATA:
      return `${value}${OFFER_REWARD_UNIT.data.toUpperCase()}`;
    case OFFER_REWARD_TYPE.CREDIT:
      return `${OFFER_REWARD_UNIT.credit}${value}`;
    default:
      logger.error('formatReward() - Unknown reward type', type);
      return '';
  }
}

function formatDuration(unit: string, value: number): string {
  return `${value} ${pluralise(value, unit.toLowerCase(), 's')}`;
}

// Get the template name for an Available Offer or Applied Reward
export const getRewardTemplateName = (
  reward: IAppliedOfferReward | ICatalogOfferReward,
  type: OFFER_TEMPLATE_TYPE
): string => {
  // RewardType: Data/Credit/Unknown
  const rewardType = getRewardType(reward);

  // Not a known reward type
  if (rewardType === OFFER_REWARD_TYPE.UNKNOWN) {
    throw new Error(`getRewardTemplateName() - Unsupported reward type, ${JSON.stringify(reward)}`);
  }

  const recurrence = isRecurringReward(reward) ? OFFER_REWARD_RECURRENCE.RECURRING : OFFER_REWARD_RECURRENCE.ONCE_OFF;
  const fulfilment = getRewardFulfilmentType(reward);

  return getRewardTemplateCode(rewardType, recurrence, fulfilment, type);
};

// Template Code patterns. For Applied rewards, fulfilmentType is irrelevant
const TEMPLATE_CODE_PATTERN_APPLIED = '{{product}}_{{rewardType}}_{{recurrence}}_{{templateType}}';
const TEMPLATE_CODE_PATTERN = '{{product}}_{{rewardType}}_{{recurrence}}_{{fulfilment}}_{{templateType}}';

// Resolve a template code from offer attributes
export const getRewardTemplateCode = (
  rewardType: OFFER_REWARD_TYPE,
  recurrence: OFFER_REWARD_RECURRENCE,
  fulfilment: OFFER_REWARD_FULFILMENT_TYPE,
  templateType: OFFER_TEMPLATE_TYPE
): string => {
  const dictionary = {
    // Hard-coded to Mobile product templates until other product lines are supported
    product: REWARD_TEMPLATE_IDENTIFIERS.PRODUCT_TYPE[PRODUCT_FAMILY.MOBILE_PLANS],
    rewardType: REWARD_TEMPLATE_IDENTIFIERS.REWARD_TYPE[rewardType],
    recurrence: REWARD_TEMPLATE_IDENTIFIERS.RECURRENCE[recurrence],
    fulfilment: REWARD_TEMPLATE_IDENTIFIERS.FULFILMENT[fulfilment],
    templateType
  };
  const templatePattern =
    templateType === OFFER_TEMPLATE_TYPE.APPLIED ? TEMPLATE_CODE_PATTERN_APPLIED : TEMPLATE_CODE_PATTERN;
  const code = applyDictionary(dictionary, templatePattern);

  if (code.includes('{')) {
    logger.error('getRewardTemplateCode() - could not resolve valid template name', dictionary);
  }

  return code;
};

// Create a dictionary that includes the date in the required formats for use in Offer Templates
export const toOfferDateDictionary = (label: string, date: string | number | Date): IDictionary => ({
  [label]: formatDate(date, OFFER_DATE_FORMAT.STANDARD),
  [`${label}:short`]: formatDate(date, OFFER_DATE_FORMAT.SHORT),
  [`${label}:long`]: formatDate(date, OFFER_DATE_FORMAT.LONG)
});
