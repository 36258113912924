import { PRODUCT_FAMILY } from './product';
import { IOfferRewardProductAttribute, OFFER_CHANNEL_ACTION, OFFER_TYPE } from './api/offers';

export const OFFER_REWARD_UNIT = {
  data: 'gb',
  credit: '$',
  duration: 'month'
};

export enum OFFER_REWARD_TYPE {
  UNKNOWN = 'unknown',
  CREDIT = 'credit',
  DATA = 'data'
}

export enum OFFER_CHANNEL {
  SALESFORCE = 'salesforce',
  DIGITAL = 'digital',
  APP = 'app'
}

export interface ICatalogOffer {
  id: string;
  name: string;
  groupingId?: string;
  description: string;
  productFamily: PRODUCT_FAMILY;
  type: OFFER_TYPE;
  startDate: string;
  endDate: string;
  isActive: boolean;
  isATL: boolean;
  eligiblePlans: string[];
  eligibleChannels: OFFER_CHANNEL[];
  channelAction: OFFER_CHANNEL_ACTION;
  rewards: ICatalogOfferReward[];
}

export type ProductAttributeMap = Record<string, IOfferRewardProductAttribute['value']>;

export interface ICatalogOfferReward {
  productId: string;
  description: string;
  productAttributes: ProductAttributeMap;
}
