import { PRODUCT_FAMILY } from '../product';
import { OFFER_CHANNEL, OFFER_REWARD_TYPE } from '../offer';

export enum OFFER_CHANNEL_ACTION {
  ACTIVATION = 'activation',
  POST_ACTIVATION = 'postActivation',
  UNKNOWN = 'unknown'
}

export enum OFFER_TYPE {
  CHANNEL = 'channel',
  BATCH = 'batch',
  REMEDIATION = 'remediation',
  UNKNOWN = 'unknown'
}

export enum OFFER_REWARD_ATTRIBUTE_NAME {
  DATA_SIZE = 'dataSizeInGb',
  CREDIT_AMOUNT = 'creditAmount',
  DURATION = 'durationInMonths',
  FULFILMENT_TYPE = 'fulfilmentType',
  INVOICE_TEXT = 'invoiceText'
}

export enum OFFER_REWARD_FULFILMENT_TYPE {
  IMMEDIATE = 'immediate',
  BILL_CYCLE_ALIGNED = 'billCycleAligned',
  UNKNOWN = 'unknown'
}

export enum OFFER_REWARD_RECURRENCE {
  ONCE_OFF = 'ONCE_OFF',
  RECURRING = 'RECURRING'
}

export interface IOffers {
  offers: IOffer[];
}

export interface IOffer {
  id: string;
  name: string;
  groupingId: string;
  description: string;
  productFamily: PRODUCT_FAMILY;
  startDate: string;
  endDate: string;
  isActive: boolean;
  type: OFFER_TYPE;
  isATL: boolean;
  channelAction: OFFER_CHANNEL_ACTION;
  eligibleChannels?: OFFER_CHANNEL[];
  eligiblePlans?: string[];
  rewards: IOfferReward[];
}

export interface IOfferReward {
  productId: string;
  description: string;
  type: OFFER_REWARD_TYPE;
  subtype: OFFER_REWARD_RECURRENCE;
  productAttributes: IOfferRewardProductAttribute[];
}

export interface IOfferRewardProductAttribute {
  name: OFFER_REWARD_ATTRIBUTE_NAME;
  value: string | number | boolean;
}

export interface IAppliedReward {
  offerId: string;
  offerType: OFFER_TYPE;
  fulfilmentType: OFFER_REWARD_FULFILMENT_TYPE;
  startDate: string;
  expiryDate: string;
  durationInMonths: number;
  remainingMonths: number;
}

export interface IAppliedDataReward extends IAppliedReward {
  dataSizeInGb: number;
}

export interface IAppliedCreditReward extends IAppliedReward {
  creditAmount: number;
}

export type IAppliedOfferReward = IAppliedDataReward | IAppliedCreditReward;

export enum OFFER_STATUS {
  NEW = 'new',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  UNKNOWN = 'unknown'
}
