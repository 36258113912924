import { OFFER_REWARD_FULFILMENT_TYPE, OFFER_REWARD_RECURRENCE } from '@belong/types/api/offers';
import { OFFER_REWARD_TYPE, PRODUCT_FAMILY } from '@belong/types';

export const OFFER_DATE_FORMAT = {
  STANDARD: 'DD MMMM YYYY',
  SHORT: 'MMM YY',
  LONG: 'DD MMM YYYY'
};

export enum OFFER_TEMPLATE_TYPE {
  INFO = 'INFO',
  APPLIED = 'APPLIED',
  ACTIVATION = 'ACTIVATION',
  ACTIVATED = 'ACTIVATED',
  PLAN_CARD = 'PLAN_CARD'
}

export const REWARD_TEMPLATE_IDENTIFIERS = {
  PRODUCT_TYPE: {
    [PRODUCT_FAMILY.MOBILE_PLANS]: 'MOBILE',
    [PRODUCT_FAMILY.MOBILE_DATA_PLANS]: 'MOBILE',
    [PRODUCT_FAMILY.INTERNET]: 'NBN'
  },
  REWARD_TYPE: {
    [OFFER_REWARD_TYPE.DATA]: 'DATA',
    [OFFER_REWARD_TYPE.CREDIT]: 'CREDIT'
  },
  RECURRENCE: {
    [OFFER_REWARD_RECURRENCE.ONCE_OFF]: 'ONCE_OFF',
    [OFFER_REWARD_RECURRENCE.RECURRING]: 'RECURRING'
  },
  FULFILMENT: {
    [OFFER_REWARD_FULFILMENT_TYPE.IMMEDIATE]: 'IMMEDIATE',
    [OFFER_REWARD_FULFILMENT_TYPE.BILL_CYCLE_ALIGNED]: 'BILL_CYCLE_ALIGNED'
  }
};
