import structuredClone from '@ungap/structured-clone';
import { OFFER_CHANNEL_ACTION } from '@belong/types/api/offers';
import { OfferContentManager } from '../OfferContentManager';

// {MOBILE/MOBILE-DATA}-PLAN-PAGE: Override to find active/ATL Offers for each plan and add them to the PlanCard props
export const override = (_correlationId: string, contentManager: OfferContentManager, section: any): any => {
  // Inject ATL offers into plan cards
  const updatedPlans = section.plans.map(planCard => {
    const offerDetails = contentManager.getOfferForPlan(planCard.plan.productCode, OFFER_CHANNEL_ACTION.ACTIVATION);

    return {
      ...planCard,
      offerDetails
    };
  });

  return structuredClone({ ...section, plans: updatedPlans });
};

export default {
  // always include base-path in paths
  path: [
    '/go/mobile',
    '/go/data-plans',
    '/go/mobile/campaign/25gb-sim-only-plan',
    '/go/mobile/campaign/40gb-sim-only-plan',
    '/go/mobile/campaign/100gb-sim-only-plan',
    '/go/mobile/campaign/160gb-sim-only-plan'
  ],
  contentType: ['sectionPlanSummary'],
  override
};
